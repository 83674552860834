<template>
  <div class="test">
    <el-header class="header">
      <el-row class="edit-header">
        <el-col :span="5" class="lf-bt">
          <el-button type="warning" @click="back" style="width: 100px"
            >Go Back</el-button
          >
        </el-col>
      </el-row>
    </el-header>

    <el-container class="container">
      <el-main class="main">
        <div class="ques">
          <div class="title">Manage Prompts</div>
        </div>
        <div id="main-body">
          <div
            style="display: flex; flex-direction: column; align-items: center;"
          >
            <el-card
              v-for="(prompt, index) in promptsList"
              class="box-card"
              :key="index"
              :body-style="{ padding: '0px 25px' }"
            >
              <div
                slot="header"
                style="display: flex;justify-content: space-between;"
              >
                <el-row style="text-align: left; font-size: 16px;">
                  <el-col :span="20">id: {{ prompt.id }}</el-col>
                  <el-col :span="20"
                    >class_name: {{ prompt.class_name }}</el-col
                  >
                  <el-col :span="20"
                    >description: {{ prompt.description }}</el-col
                  >
                  <el-col :span="20">version: {{ prompt.version }}</el-col>
                  <el-col :span="20"
                    >stable_versions: {{ prompt.stable_versions }}</el-col
                  >
                </el-row>
                <el-row>
                  <div class="headspan">
                    Create_Time: {{ prompt.create_time }}
                  </div>
                  <div class="headspan">
                    Update_Time: {{ prompt.update_time }}
                  </div>
                </el-row>
              </div>
              <div slot="default" class="card-body">
                <el-button
                  type="text"
                  class="rightside el-icon-delete"
                  @click="deletePrompt(index)"
                >
                  Delete</el-button
                >
                <el-button
                  type="text"
                  class="rightside el-icon-document"
                  @click="copyPrompt(index)"
                >
                  Copy</el-button
                >
                <el-button
                  type="text"
                  class="rightside el-icon-document"
                  @click="openEditPromptDialog(prompt)"
                  >&nbsp; Edit
                </el-button>
                <el-button
                  type="text"
                  class="rightside el-icon-document"
                  @click="setStable(prompt)"
                  >&nbsp; Set to Stable Version
                </el-button>
              </div>
            </el-card>

            <!-- <el-pagination
              layout="sizes, prev, pager, next, jumper"
              background
              :current-page="currentPage"
              :page-size="pageSize"
              :page-sizes="pageSizes"
              :total="QnList.length"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              style="margin: 20px"
            >
            </el-pagination> -->
          </div>
        </div>
      </el-main>
    </el-container>
    <el-dialog
      title="Edit Prompts"
      :visible.sync="editPromptDialogVisible"
      width="80%"
    >
      <vue-json-editor
        v-model="currentPromptObj"
        :options="editorOptions"
      ></vue-json-editor>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editPromptDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editPromptDialogConfirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import VueJsonEditor from "vue-json-editor";

export default {
  data() {
    return {
      promptsList: [],
      editPromptDialogVisible: false,
      currentCardId: "",
      currentPromptObj: null,
      editorOptions: {
        mode: "code", // 设置编辑模式，其他可选值比如 'tree', 'form'
        mainMenuBar: true,
        navigationBar: true,
        statusBar: true,
      },
    };
  },
  components: { VueJsonEditor },
  methods: {
    async setStable(prompt) {
      //如果是.cn的中国平台，弹出提示框，要求前往英国平台
      if (this.getTopLevelDomain() == "cn") {
        console.log(`*********** .cn ***********`);
        window.alert("请前往英国站管理后台修改 https://mgmt.oxvalue.ai ");
        return;
      }

      console.log(prompt);
      this.currentCardId = prompt.id;
      await this.$axios({
        method: "get",
        url: `https://api.oxvalue.ai/api/prompt/v0.1/prompt_class/${prompt.id}/`,
        headers: {
          Authorization: window.localStorage.getItem("ova_admin_token"),
        },
      }).then((res) => {
        console.log(res);
        let prompt_data = {};
        prompt_data.class_name = res.data.data.class_name;
        prompt_data.description = res.data.data.description;
        prompt_data.prompt_zh = JSON.parse(res.data.data.prompt_zh);
        prompt_data.prompt_en = JSON.parse(res.data.data.prompt_en);
        prompt_data.version = res.data.data.version;

        this.currentPromptObj = prompt_data;
      });
      this.$confirm(
        "Are you sure you want to set this prompt to stable version?",
        "提示",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.$axios({
          method: "PUT",
          url: `https://api.oxvalue.ai/api/prompt/v0.1/prompt_class/${this.currentCardId}/`,
          headers: {
            Authorization: window.localStorage.getItem("ova_admin_token"),
          },
          data: JSON.stringify({
            prompt_zh: JSON.stringify(this.currentPromptObj.prompt_zh),
            prompt_en: JSON.stringify(this.currentPromptObj.prompt_en),
            stable_version: this.currentPromptObj.version,
          }),
        }).then((res) => {
          if (res.data.code == "200") {
            console.log(`版本修改成功`);
            console.log(res);

            this.currentPromptObj = null;
            this.currentCardId = "";
            this.editPromptDialogVisible = false;

            this.$message({
              message: "版本修改成功",
              type: "success",
            });

            this.getAllPrompts();
          } else {
            console.log(`版本修改失败!`);
            this.$message.error(res.data.error);
          }
        });
      });
    },
    back() {
      this.$router.push("/index");
    },
    getTopLevelDomain() {
      // ai写的获取顶级域名的函数
      try {
        // 获取当前页面的主机名
        const hostname = window.location.hostname;
        // 将主机名按点分割成数组
        const parts = hostname.split(".");
        // 如果数组长度小于2，说明格式不正确
        if (parts.length < 2) {
          return null;
        }
        // 获取最后一个部分作为顶级域名
        const tld = parts[parts.length - 1].toLowerCase();
        // 处理特殊情况，如 .com.cn, .net.cn 等
        const specialTlds = ["cn", "jp", "uk", "br", "tw"];
        if (parts.length > 2 && specialTlds.includes(tld)) {
          const secondLevelDomain = parts[parts.length - 2].toLowerCase();
          // 检查是否是双后缀情况
          if (["com", "net", "org", "gov", "edu"].includes(secondLevelDomain)) {
            return `${secondLevelDomain}.${tld}`;
          }
        }
        return tld;
      } catch (error) {
        console.error("获取顶级域名时发生错误:", error);
        return null;
      }
    },

    openEditPromptDialog(prompt) {
      //如果是.cn的中国平台，弹出提示框，要求前往英国平台
      if (this.getTopLevelDomain() == "cn") {
        console.log(`*********** .cn ***********`);
        window.alert("请前往英国站管理后台修改 https://mgmt.oxvalue.ai ");
        return;
      }

      console.log(prompt);
      this.currentCardId = prompt.id;
      this.$axios({
        method: "get",
        url: `https://api.oxvalue.ai/api/prompt/v0.1/prompt_class/${prompt.id}/`,
        headers: {
          Authorization: window.localStorage.getItem("ova_admin_token"),
        },
      }).then((res) => {
        console.log(res);
        let prompt_data = {};
        prompt_data.class_name = res.data.data.class_name;
        prompt_data.description = res.data.data.description;
        prompt_data.prompt_zh = JSON.parse(res.data.data.prompt_zh);
        prompt_data.prompt_en = JSON.parse(res.data.data.prompt_en);

        this.currentPromptObj = prompt_data;
      });
      this.editPromptDialogVisible = true;
    },
    editPromptDialogConfirm() {
      // 发api之前先判断一下class_name是否是合法字符,只能由小写字母或者数字或下划线组成
      if (this.currentPromptObj.class_name == "") {
        // 提示不能为空
        this.$message.error("Class name cannot be empty");
        return;
      } else if (!/^[a-z0-9_]+$/.test(this.currentPromptObj.class_name)) {
        this.$message.error("class_name只能由小写字母、数字、下划线组成");
        return;
      }

      this.$axios({
        method: "PUT",
        url: `https://api.oxvalue.ai/api/prompt/v0.1/prompt_class/${this.currentCardId}/`,
        headers: {
          Authorization: window.localStorage.getItem("ova_admin_token"),
        },
        data: JSON.stringify({
          class_name: this.currentPromptObj.class_name,
          description: this.currentPromptObj.description,
          prompt_zh: JSON.stringify(this.currentPromptObj.prompt_zh),
          prompt_en: JSON.stringify(this.currentPromptObj.prompt_en),
        }),
      }).then((res) => {
        if (res.data.code == "200") {
          console.log(`修改成功`);
          console.log(res);

          this.currentPromptObj = null;
          this.currentCardId = "";
          this.editPromptDialogVisible = false;

          this.$message({
            message: "修改成功",
            type: "success",
          });

          this.getAllPrompts();
        } else {
          console.log(`修改失败!`);
          this.$message.error(res.data.error);
        }
      });
    },
    deletePrompt(index) {
      //如果是.cn的中国平台，弹出提示框，要求前往英国平台
      if (this.getTopLevelDomain() == "cn") {
        console.log(`*********** .cn ***********`);
        window.alert("请前往英国站管理后台修改 https://mgmt.oxvalue.ai ");
        return;
      }

      this.$confirm("Are you sure you want to delete this prompt?", "提示", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            method: "put",
            url: `https://api.oxvalue.ai/api/prompt/v0.1/prompt_class/${this.promptsList[index].id}/`,
            headers: {
              Authorization: window.localStorage.getItem("ova_admin_token"),
            },
            data: JSON.stringify({
              is_deleted: "true",
            }),
          }).then((res) => {
            if (res.data.code == "200") {
              console.log(res);
              this.promptsList.splice(index, 1);
              this.$message({
                type: "success",
                message: "Prompt Deleted!",
              });
            } else {
              this.$message.error("API error!");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Cancelled",
          });
        });
    },
    copyPrompt(index) {
      //如果是.cn的中国平台，弹出提示框，要求前往英国平台
      if (this.getTopLevelDomain() == "cn") {
        console.log(`*********** .cn ***********`);
        window.alert("请前往英国站管理后台修改 https://mgmt.oxvalue.ai ");
        return;
      }

      // 要复制得先get到当前的prompt 然后再改个class_name复制过去
      this.$axios({
        method: "get",
        url: `https://api.oxvalue.ai/api/prompt/v0.1/prompt_class/${this.promptsList[index].id}/`,
        headers: {
          Authorization: window.localStorage.getItem("ova_admin_token"),
        },
      }).then((res) => {
        let prompt_copy = {
          class_name: res.data.data.class_name + "_copy",
          description: res.data.data.description,
          prompt_zh: res.data.data.prompt_zh,
          prompt_en: res.data.data.prompt_en,
        };
        console.log(prompt_copy);

        this.$axios({
          method: "post",
          url: `https://api.oxvalue.ai/api/prompt/v0.1/prompt_class/`,
          headers: {
            Authorization: window.localStorage.getItem("ova_admin_token"),
          },
          data: JSON.stringify(prompt_copy),
        }).then((res) => {
          if (res.data.code == "200") {
            console.log(res);
            console.log(`复制成功`);
            this.getAllPrompts();
            this.$message({
              type: "success",
              message: `Prompt Copied! New prompt id ${res.data.data.id}`,
            });
          } else {
            this.$message.error(res.data.error);
          }
        });
      });
    },
    getAllPrompts() {
      this.$axios({
        method: "get",
        url: `https://api.oxvalue.ai/api/prompt/v0.1/prompt_class/`,
        headers: {
          Authorization: window.localStorage.getItem("ova_admin_token"),
        },
      })
        .then((res) => {
          console.log(`prompt_class_list`);
          console.log(res);
          if (res.data["code"] == 403) {
            this.$store.dispatch("clear");
            window.localStorage.removeItem("ova_admin_token");
            this.$router.push("/login");
            return;
          }
          this.promptsList = [];

          let prompt_class_list = res.data["data"]["prompt_class_list"];

          for (let p in prompt_class_list) {
            let prompt = {
              id: prompt_class_list[p]["id"],
              class_name: prompt_class_list[p]["class_name"],
              create_time: prompt_class_list[p]["create_time"],
              update_time: prompt_class_list[p]["update_time"],
              description: prompt_class_list[p]["description"],
              version: prompt_class_list[p]["version"],
              stable_versions: prompt_class_list[p]["stable_versions"],
            };
            this.promptsList.push(prompt);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {},
  created() {
    this.getAllPrompts();
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style>
.rightside {
  float: right;
  padding: 10px;
  color: black;
  font-size: 14px;
}
.box-card {
  margin: 25px 50px;
  width: 70%;
  height: auto;
  font-size: 15px;
}
.headspan {
  padding: 7px 7px;
}
.test .linkDialog {
  text-align: left;
}
.test .container {
  padding: 0;
  height: auto;
  min-height: 610px;
}

.test header {
  padding: 0 100px;
}

.test .el-container {
  padding: 0 100px;
}

.test .side {
  border-top: solid 1px #e3e3e3;
  border-right: solid 1px #e3e3e3;
  background: #ffffff;
}

.test .main {
  border-top: solid 1px #e3e3e3;
  background: #ffffff;
}

.test .edit {
  margin-top: 0;
  overflow: auto;
  height: 550px;
}

.test .outline {
  overflow: auto;
  height: 550px;
}

.test .ques-type {
  padding: 15px 0;
  font-size: 16px;
  border-bottom: dashed #e3e3e3 1px;
}

.test .edit-title {
  color: black;
  padding: 15px 0;
  font-size: 16px;
  border-bottom: solid #e3e3e3 2px;
}

.test .edit-ques {
  padding: 15px 0;
  font-size: 16px;
  border-bottom: dashed #e3e3e3 1px;
}

.test .type-icon {
  color: #1687fd;
  display: inline-block;
}

.test .type-icon:hover {
  color: #409eff;
  cursor: pointer;
}

.test .el-tabs__nav-scroll {
  text-align: center;
  height: 60px;
  margin: 0 60px;
}

.test .el-tabs__item {
  font-weight: bold;
  padding: 0 20px;
  height: 60px;
  box-sizing: border-box;
  line-height: 60px;
  display: inline-block;
  list-style: none;
  font-size: 16px;
  color: black;
  position: relative;
}

.test .el-tabs__header {
  margin: 0;
}

.test .el-tree-node__content {
  padding-left: 10px !important;
  height: 40px;
}

/* .test .main {
  max-height: 610px;
} */

.test .ques .title {
  font-size: 28px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 26px;
}

.test .ques .description {
  text-align: left;
  font-size: 16px;
  padding-bottom: 30px;
  color: black;
  line-height: 30px;
}

.test .ques-block {
  padding-bottom: 15px;
  border-top: solid #e3e3e3 1px;
}

.test .ques-block:hover {
  background: #f5f5f5;
  transition: 0.3s;
}

.test .ques-block .must {
  font-weight: normal;
  color: crimson;
}

.test .block-title {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 20px 10px 10px;
  font-weight: bold;
}

.test .block-description {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 10px;
  color: #969696;
}

.test .block-refer {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #51c215;
}

.test .block-point {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #e59824;
}

.test .block-choice {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 5px 10px 10px;
}

.test .el-button-group > .el-button:first-child {
  border-radius: 0 0 0 8px;
}

.test .el-button-group > .el-button:last-child {
  border-radius: 0 0 8px 0;
}

.test .block-button .el-button {
  background: #b9b9b9;
  border: transparent;
  padding: 12px 16px;
  font-size: 16px;
}

.test .block-button .el-button:hover {
  background: #989898;
  border: transparent;
}

.test .bt {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.test .block-choice .el-textarea__inner {
  max-height: 100px;
}

.test .dialog {
  text-align: left;
  font-size: large;
}
.test .addOptionButton {
  display: inline-block;
  margin-left: 100px;
}
.test .deleteOptionButton {
  margin-left: 20px;
}

.test .logic-description {
  margin-top: -15px;
  margin-bottom: 30px;
  text-align: left;
  padding-left: 6px;
  font-size: 14px;
  color: #aaaaaa;
}

.test .important {
  color: crimson;
}

.test .logic-title {
  font-size: 16px;
  font-weight: bold;
  padding-left: 12px;
  padding-bottom: 30px;
}

.test .logic-bt {
  text-align: center;
}

.test .addOptionButton {
  display: inline-block;
  margin-left: 100px;
  margin-bottom: 22px;
}

.test .deleteOptionButton {
  margin-left: 20px;
}

.test .settingDialog {
  text-align: center;
}

.test .settingDialog .el-dialog__body {
  padding-left: 30px;
}

.test .logic-form .el-input--suffix .el-input__inner {
  width: 620px;
}

.test .logic-info {
  color: #aaaaaa;
  padding-top: 2px;
  padding-left: 12px;
  padding-bottom: 25px;
}

.test .block-relation {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #1687fd;
}
</style>
